import React, { useEffect, useState } from 'react';

import { Layout, SEO } from '../components';

export default function GiftVoucher() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (function (g) {
      const i = g.createElement('script');
      const f = g.querySelectorAll('script')[0];
      i.async = 1;
      i.src = 'https://cdn.giftup.app/dist/gift-up.js';
      f.parentNode.insertBefore(i, f);
    })(document);
  }, []);

  setTimeout(() => {
    setIsLoaded(true);
  }, 2000);

  let content;

  if (!isLoaded) {
    content = (
      <div className="absolute inset-0 z-10 flex justify-center flex-1 min-h-screen bg-white">
        <h2 className="font-serif text-2xl font-semibold leading-tight text-center uppercase">
          Loading...
        </h2>
      </div>
    );
  }
  return (
    <Layout>
      <SEO title="Gift Voucher" />
      {content}
      <div
        className="gift-up-target"
        data-site-id="a0115535-5c3d-4925-b7cb-332e007a80c5"
        data-platform="Other"
      />
      <article
        id="conditions"
        className="max-w-2xl px-4 pt-6 pb-20 mx-auto sm:px-6 lg:px-8"
      >
        <h2 className="mt-6 font-bold uppercase">Conditions of use:</h2>
        <ul className="ml-5 list-disc">
          <li>
            Voucher is non-refundable, however, may be transferred to another
            person.
          </li>
          <li>Not redeemable for cash nor will change be given.</li>
          <li>Lost or stolen cards will not be replaced.</li>
          <li>
            24hrs noticed is required for cancellations & rescheduling. Failure
            to do so will result in voucher being deemed void.
          </li>
          <li>Not to be used on Sundays or Public Holidays.</li>
        </ul>
      </article>
    </Layout>
  );
}
